import React, { ReactElement } from 'react'
import { GetStaticProps } from 'next'
import { CourseConfigType } from 'common/src/courseConfig'
import { SplashPage } from '@/components/static/SplashPage/SplashPage'
import {
    fetchSplashPageProps,
    SplashPageProps,
} from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
export const getStaticProps = (async (_context) => {
    const response = await fetchSplashPageProps(CourseConfigType.fsaHealth)
    return { props: response }
}) satisfies GetStaticProps<SplashPageProps>

// MUST be named SplashPageExport
const SplashPageExport = (props: SplashPageProps): ReactElement => {
    return <SplashPage {...props} />
}

SplashPageExport.isStatic = true

export default SplashPageExport
